/* global tinymce:true */
/* global $:true */
/* eslint-disable */
(function ()
{
    var pluginManager  =  tinymce.util.Tools.resolve('tinymce.PluginManager');

    pluginManager.add('floatingtoolbar', function(editor)
    {
        function move(editor, scroll, focus)
        {
            var container = $(editor.editorContainer);
            var toolbar = container.find('.tox-editor-header');
            var containerRect = container.get(0).getBoundingClientRect();
            var toolbarRect = toolbar.get(0).getBoundingClientRect();
            var scrollRect = scroll.get(0).getBoundingClientRect();

            var minWidth = editor.settings.floatingtoolbar_minwidth || 0;
            var minHeight = editor.settings.floatingtoolbar_minheight || 0;
            var top = $(window).width() > minWidth && $(window).height() > minHeight ? editor.settings.floatingtoolbar_top == 'auto' ? scrollRect.top : editor.settings.floatingtoolbar_top : 0;
            var bottom = $(window).width() > minWidth && $(window).height() > minHeight ? editor.settings.floatingtoolbar_bottom : 0;

            if (focus && (containerRect.top < top) && (containerRect.top + containerRect.height - top - toolbarRect.height - bottom > 0))
            {
                container.css({
                    'padding-top': toolbarRect.height
                });
                toolbar.css({
                    'position': 'fixed',
                    'z-index': 1020,
                    'box-shadow': '#666666 0px 0px 2px',
                    'width': containerRect.width - 2,
                    'top': `${top}px`
                });
            }
            else
            {
                container.css({
                    'padding-top': '0px'
                });
                toolbar.css({
                    'position': 'static',
                    'box-shadow': 'none',
                    'width': 'auto',
                    'top': '0px'
                });
            }
        }

        editor.on('init',function()
        {
            editor.settings.floatingtoolbar_top = editor.settings.floatingtoolbar_top || 'auto';
            editor.settings.floatingtoolbar_bottom = editor.settings.floatingtoolbar_bottom || 300;
            editor.settings.floatingtoolbar_selectors = editor.settings.floatingtoolbar_selectors || [window];
        });

        editor.on('focus',function()
        {
            $.each(editor.settings.floatingtoolbar_selectors, function(i, selector)
            {
                var scroll = $(selector);

                scroll.on('scroll.editor resize.editor', function()
                {
                    move(editor, scroll, true);
                });

                move(editor, scroll, true);
            });
        });

        editor.on('blur',function()
        {
            $.each(editor.settings.floatingtoolbar_selectors, function(i, selector)
            {
                var scroll = $(selector);

                scroll.off('scroll.editor resize.editor');

                move(editor, scroll, false);
            });
        });
    });
})();
