
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { baseurl } from '@/helpers/Utils';
import Permissions from '@/settings/permissions';
import StorageService from '@/services/admin/StorageService';
import merge from 'lodash/merge';
import head from 'lodash/head';
import * as xss from 'xss';

// Import TinyMCE
import tinymce from 'tinymce/tinymce';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/emoticons';
import 'tinymce/icons/default';
// import 'tinymce/plugins/codesample';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/hr';
// import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
// import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/paste';
// import 'tinymce/plugins/print';
import 'tinymce/plugins/searchreplace';
// import 'tinymce/plugins/tabfocus';
import 'tinymce/plugins/template';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autoresize';
// import 'tinymce/plugins/bbcode';
import 'tinymce/plugins/code';
import 'tinymce/plugins/directionality';
// import 'tinymce/plugins/fullpage';
// import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
// import 'tinymce/plugins/importcss';
// import 'tinymce/plugins/legacyoutput';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/nonbreaking';
// import 'tinymce/plugins/pagebreak';
// import 'tinymce/plugins/preview';
import 'tinymce/plugins/save';
// import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/table';
// import 'tinymce/plugins/toc';
import 'tinymce/plugins/visualchars';

// Custom plugins
// import '../tinymce/plugins/linktools';
import '../tinymce/plugins/floatingtoolbar';

// Language
import '../tinymce/langs/pl';

// A theme is also required
import 'tinymce/themes/silver';

@Options({
    name: 'editor',
    components: {
        // 'tinymce': TinyMCE
    }
})
export default class Editor extends Vue
{
    @Prop()
    public id!: string;

    @Prop({ default: '' })
    public modelValue: string;

    @Prop({
        default: () => [
            'advlist autolink autosave lists link image charmap hr anchor autoresize',
            'searchreplace wordcount visualblocks visualchars code template',
            'insertdatetime media nonbreaking save table directionality',
            'paste textpattern fullscreen floatingtoolbar'
            // 'gallery filemanager mention localautosave'
        ]
    })
    private plugins: string[];

    @Prop({
        default: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link gallery_insert filemanager_insert | forecolor backcolor | nonbreaking template fullscreen code'
    })
    private toolbar: string;

    @Prop({ default: () => {} })
    public editorOptions: Record<string, any>;

    private content: string = '';
    private modal: boolean = false;
    private media: string = "files";
    private filemanager: any = {
        enabled: false,
        callback: null,
        value: null,
        meta: null
    };

    private xssWhiteList: any = {
        iframe: ['width', 'height', 'src', 'frameborder', 'allow', 'allowfullscreen']
    };

    private xssOptions: xss.IFilterXSSOptions = {
        whiteList: merge(xss.getDefaultWhiteList(), this.xssWhiteList) as any,
        onIgnoreTagAttr: (tag: string, name: string, value: string, isWhiteAttr: any) =>
        {
            const allowed = ['id', 'style', 'class', 'contenteditable'];

            if (name.substr(0, 5) === "data-" || allowed.includes(name))
            {
                return `${name}="${value}"`;
            }
        }
    };

    private editor(): any
    {
        return tinymce.get(this.id);
    }

    public async mounted(): Promise<void>
    {
        this.filemanager.enabled = await this.$permissions.all([Permissions.Storage.View]);
        this.$events.$on('filemanager::modal::close', this.closeModal);
        this.$events.$on('filemanager::callback::modelValue', this.callbackValue);

        tinymce.init(this.settings(this));
    }

    public unmounted(): void
    {
        tinymce.remove(this.editor());

        this.$events.$off('filemanager::modal::close', this.closeModal);
        this.$events.$off('filemanager::callback::modelValue', this.callbackValue);
    }

    private filterXSS(value: string): string
    {
        return xss.filterXSS(value, this.xssOptions);
    }

    private settings(ctx: any): any
    {
        const updateImage = (el: any): void =>
        {
            const src = el.attr('src');

            if (src.indexOf('#(storage):') == 0)
            {
                const publicId = src.replace('#(storage):', '');

                el.attr('src', StorageService.previewResource(publicId));
                el.attr('data-src', src);
            }
        };

        const displayImages = (editor: any, content: string): string =>
        {
            const html = editor.$('<div></div>').html(content);

            html.find('img').toArray().forEach((img: any) =>
            {
                updateImage(editor.$(img));
            });

            return html.html();
        };

        const serializeImages = (editor: any, content: string): string =>
        {
            // Zablokowanie doczytywania obrazkow przez parser jquery
            content = content.replace(new RegExp(' src="', 'g'), ' src-disabled="');

            const html = editor.$('<div></div>').html(content);

            html.find('img').toArray().forEach((img: any) =>
            {
                const el = editor.$(img);
                const src = el.attr('data-src');

                if (src && src.indexOf('#(storage):') == 0)
                {
                    el.attr('src-disabled', src);
                    el.removeAttr('data-src');
                }
            });

            // Przywrocenie oryginalnego kodu html
            return html.html().replace(new RegExp(' src-disabled="', 'g'), ' src="');
        };

        const options = {
            base_url: '/static/tinymce',
            selector: '#' + this.id,
            language: ctx.$i18n.shortLocale(),
            skin_url: '/static/skins/oxide',
            content_css: '/static/skins/oxide/content.min.css',
            mobile: {
                theme: 'silver'
            },
            toolbar: this.toolbar,
            toolbar_mode: 'sliding',
            menubar: 'edit insert view format tools',
            plugins: this.plugins,
            templates: this.templates(),
            relative_urls: false,
            paste_data_images: false,
            browser_spellcheck: true,
            entity_encoding: "raw",
            min_height: 450,
            autoresize_bottom_margin: 50,
            autoresize_on_init: true,
            nowrap: false,
            resize: false,
            table_style_by_css: true,
            media_alt_source: false,
            media_poster: false,
            image_advtab: true,
            floatingtoolbar_selectors: ['div.content.scroll'],
            // las_seconds: 15,
            // extended_valid_elements : "edito[class|data*],edito-gallery[class|data*],edito-files[class|data*],div[class|data*]",
            // custom_elements: "edito,edito-gallery,edito-files",
            file_picker_callback: !this.filemanager.enabled ? null : (callback: any, value: any, meta: any) =>
            {
                ctx.modal = true;
                ctx.filemanager.callback = callback;
                ctx.filemanager.value = value;
                ctx.filemanager.meta = meta;

                switch (meta.filetype)
                {
                    case "image":
                        ctx.media = "images";
                        break;
                    case "media":
                        ctx.media = "media";
                        break;
                    case "file":
                        ctx.media = "files";
                        break;
                    default:
                        ctx.media = "files";
                        break;
                }
            },
            setup: (editor: any) =>
            {
                editor.on('init', (e: Event) =>
                {
                    ctx.content = this.filterXSS(this.modelValue);
                    editor.setContent(ctx.content);
                });
                editor.on('change keyup undo redo', () =>
                {
                    ctx.content = serializeImages(editor, editor.getContent());
                    ctx.updateModel(ctx.content);
                });
                editor.on('BeforeSetContent', (e: any) =>
                {
                    if (e.content)
                    {
                        e.content = this.filterXSS(e.content);
                        e.content = displayImages(editor, e.content);
                    }
                });
                editor.on('PostProcess', (e: any) =>
                {
                    if (e.content && e.source_view)
                    {
                        e.content = serializeImages(editor, e.content);
                    }
                    if (e.content && !e.source_view)
                    {
                        e.content = displayImages(editor, e.content);
                    }
                });
                editor.on('NodeChange', (e: any) =>
                {
                    if (e.element && e.element.tagName == "IMG")
                    {
                        const el = editor.$(e.element);
                        const src = el.attr('src');

                        if (src.startsWith('#(storage):'))
                        {
                            updateImage(editor.$(e.element));
                        }
                    }
                });
                editor.on('OpenWindow', (e: any) =>
                {
                    const data = e.dialog.getData();

                    // Image
                    if (data?.src?.value?.length > 0 && data?.src?.value.startsWith(baseurl()))
                    {
                        e.dialog.setData({
                            src: {
                                value: `#(storage):${data.src.value.split('/').pop()}`
                            }
                        });
                    }
                });
            }
        };

        return merge(options, this.editorOptions);
    }

    @Emit('update:modelValue')
    private updateModel(content: string): string
    {
        return content;
    }

    private callbackValue(files: any[]): void
    {
        if (this.modal === true)
        {
            const file = head(files);
            const params = this.filemanager;

            if (file)
            {
                const url = `#(storage):${file.publicId}`;
                const size = this.$filters.filesize(file.contentLength);
                const text = `${file.name} [${size}]`;

                if (params.meta.filetype === 'file')
                {
                    params.callback(url, { text: params.meta.text || text, title: file.name });
                }
                if (params.meta.filetype === 'image')
                {
                    params.callback(url, { alt: file.name, width: file.meta.width, height: file.meta.height });
                }
                if (params.meta.filetype === 'media')
                {
                    params.callback(url, { width: 480, height: 270 });
                }
            }

            this.closeModal();
        }
    }

    private closeModal(): void
    {
        this.modal = false;
    }

    @Watch('modelValue')
    private onValueChanged(value: string, old: string): void
    {
        if (this.editor() && value !== this.content && value !== old)
        {
            value = this.filterXSS(value);
            this.editor().setContent(value);
            this.content = value;
        }
    }

    private templates(): any[]
    {
        return [
            {
                title: '1/2',
                description: this.$t('[[[Wstaw dwie równe kolumny]]]'),
                content: `
                    <div class="row">
                        <div class="medium-6 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                        <div class="medium-6 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                    </div>
                    <p></p>
                `
            },
            {
                title: '2/3 - 1/3',
                description: this.$t('[[[Wstaw dwie kolumny 2/3 oraz 1/3]]]'),
                content: `
                    <div class="row">
                        <div class="medium-8 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                        <div class="medium-4 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                    </div>
                    <p></p>
                `
            },
            {
                title: '1/3',
                description: this.$t('[[[Wstaw trzy kolumny 1/3]]]'),
                content: `
                    <div class="row">
                        <div class="medium-4 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                        <div class="medium-4 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                        <div class="medium-4 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                    </div>
                    <p></p>
                `
            },
            {
                title: '1/4',
                description: this.$t('[[[Wstaw cztery kolumny 1/4]]]'),
                content: `
                    <div class="row">
                        <div class="medium-3 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                        <div class="medium-3 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                        <div class="medium-3 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                        <div class="medium-3 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                    </div>
                    <p></p>
                `
            },
            {
                title: '1/3 - 2/3',
                description: this.$t('[[[Wstaw dwie kolumny 1/3 oraz 2/3]]]'),
                content: `
                    <div class="row">
                        <div class="medium-4 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                        <div class="medium-8 small-12 column edito-column">
                            &nbsp;Lorem ipsum...
                        </div>
                    </div>
                    <p></p>
                `
            },
            {
                title: this.$t('[[[Ważne informacje]]]'),
                description: this.$t('[[[Wstaw szablon informacyjny ze zdjęciem]]]'),
                content: `
                    <div class="row custom-template" data-equalizer="column">
                        <div class="medium-3 small-5 column image-template" data-equalizer-watch="column">
                            <img src="/static/img/logo.png">
                        </div>
                        <div class="medium-9 small-7 column desc" data-equalizer-watch="column">
                            &nbsp;Treść komunikatu
                        </div>
                    </div>
                    <p></p>
                `
            }
        ];
    }
}
